<template>
    <div class="daping-main">
        <div class="daping-top">
            <div class="title">LIMS大数据平台</div>
            <div class="back" @click="backhome">
                <i><img src="http://api.tengbocn.com/upload/assets/daping-home.png" alt=""></i> 返回首页
            </div>
            <div class="datatime">{{current_day}}</div>
        </div>
        <div class="daping-mid">
            <div class="daping-mid-left">
                <div class="baogaonumcontainer">
                    <div class="title">近7日出具报告数量</div>
                    <div class="maincon" id="baogaoshu" style="width: 430px;height: 260px; margin-top: 0px"></div>
                </div>
                <div class="baogaonumcontainer">
                    <div class="title">近7日试剂出入库数量</div>
                    <div class="maincon" id="shijikucun" style="width: 430px;height: 270px; margin-top: 20px"></div>
                </div>
                <div class="tognzhicontainer">
                    <div class="title">通知公告</div>
                    <div class="maincon" v-if="key== 0" v-for="( item,key) in Announcementdata" :key="key">
                        <div style="text-align: center; font-size: 20px; font-weight: bold; margin-bottom: 10px">
                            {{item.title}}
                        </div>
                        {{item.content}}
                    </div>
                </div>
            </div>
            <div class="daping-mid-container">
                <div class="daping-mid-contop">
                    <div class="daping-mid-contop-left">
                        <div class="daping-mid-contop-left-title">今日采集任务数</div>
                        <div class="daping-mid-contop-left-con">{{taskdata.spec_item_num}}</div>
                    </div>
                    <div class="daping-mid-contop-right">
                        <div class="daping-mid-contop-right-title">今日检验任务数</div>
                        <div class="daping-mid-contop-right-con">23565</div>
                    </div>
                </div>
                <div class="daping-mid-smaple-container">
                    <div class="title">近30天采集样品数</div>
                    <div id="sampledata" style="width: 900px;height: 275px; margin-top: 0px"></div>
                </div>
                <div class="daping-kit-container">
                    <div class="title">实验室</div>
                    <div class="daping-kit-container-con">
                        <div class="daping-kit-container-con-list" v-for="( item,key) in laboratorydata" :key="key">

                            <div v-if="item.laboratory_temperature == 1 || item.laboratory_humidity == 1">
                                <img src="http://api.tengbocn.com/upload/assets/dapingkit2.png" alt=""></div>

                            <div v-else><img src="http://api.tengbocn.com/upload/assets/dapingkit1.png" alt=""></div>

                            <div class="kitname">{{item.name}}</div>
                            <div class="kitcon">
                                <div class="kit-wudu">
                                    <i><img src="http://api.tengbocn.com/upload/assets/dapingwendu.png" alt=""></i>
                                    <span v-if="item.laboratory_temperature == 1" class="fcr"> {{item.temperature}}℃</span>
                                    <span v-else>{{item.temperature}}℃</span>

                                </div>
                                <div class="kit-shidu">
                                    <i><img src="http://api.tengbocn.com/upload/assets/dapingshidu.png" alt=""></i>
                                    <span v-if="item.laboratory_humidity == 1" class="fcr">
                                        <span v-if="item.humidity == '无数值'" class="fcr"> {{item.humidity}}</span>
                                        <span v-else class="fcr"> {{item.humidity}}%</span>
                                       </span>
                                    <span v-else> {{item.humidity}}%</span>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div class="daping-mid-right">

                <div class="daping-weituodan-container">
                    <div class="title">近7日委托单数量</div>
                    <div id="weituodannum" style="width: 430px;height: 260px; margin-top: 0px"></div>
                </div>

                <div class="daping-yiqi-container">
                    <div class="title">仪器设备</div>
                    <div class="dapingyiqi-con">
                        <div class="dapingyiqi-con-title">
                            <div class="dapingyiqi-con-title-left">名称</div>
                            <div class="dapingyiqi-con-title-right">状态</div>
                        </div>
                        <div class="dapingyiqi-con-mid">
                            <div class="dapingyiqi-con-mid-list" v-for="( item,key) in apparatusdata" :key="key">
                                <div class="dapingyiqi-con-mid-list-left">{{item.name}}</div>
                                <div class="dapingyiqi-con-mid-list-right" v-if="item.used_status===1 || item.used_status===2 || item.used_status===4 || item.used_status===6 || item.used_status===7">
                                    {{wordType(item.used_status)}}
                                </div>
                                <div class="dapingyiqi-con-mid-list-right fcr" v-if="item.used_status===3 || item.used_status===5">
                                    {{wordType(item.used_status)}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
    export default {
        components: {},
        data() {
            return {
                Announcementdata: [],
                apparatusdata: [],
                laboratorydata: [],
                taskdata: '',
                current_day: ''
            }
        },
        mounted() {
            this.baogao7Day()   //近7日出具报告数量
            this.shiji7Day()  //近7日试剂出入库数量
            this.smaple30Day()   //近30天采集样品数
            this.Get_daping()  //大屏数据
            this.Dp_num()   //今日任务数
            this.weituodan() //7日委托单数
            this.Dp_data() //大屏当前日期

        },
        methods: {
            backhome() {
                this.$router.push({name: 'home'})
            },
            Dp_data() {
                this.$sa0.post({
                    url: this.$api('Dp_data'),
                    data: {}
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.current_day = response.data.current_day;
                            setTimeout(() => {
                                this.Dp_data()
                            }, 1000 * 60)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // 类型转换
            wordType(status) {
                let type_arr = ['空闲', '再用', '送检中', '借出', '维修中', '领用待确认', '归还待确认']
                if (type_arr.length >= Number(status)) {
                    return type_arr[Number(status) - 1]
                } else {
                    return status
                }
            },


            Dp_num() {
                this.$sa0.post({
                    url: this.$api('Dp_num'),
                    data: {}
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.taskdata = response.data.result;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })

            },


            Get_daping() {
                this.$sa0.post({
                    url: this.$api('Get_daping'),
                    data: {}
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Announcementdata = response.data.result.announcement;
                            this.laboratorydata = response.data.result.laboratory;
                            this.apparatusdata = response.data.result.apparatus;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            baogao7Day() {
                this.$sa0.post({
                    url: this.$api('Daping_report'),
                    data: {}
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            var chartDom = document.getElementById('baogaoshu');
                            var myChart = echarts.init(chartDom);
                            var option;
                            let data = response.data.data;
                            console.log(response.data.data)
                            option = {
                                tooltip: {
                                    trigger: 'axis',
                                    axisPointer: {
                                        type: 'shadow',
                                        label: {
                                            backgroundColor: '#6a7985'
                                        }
                                    }
                                },
                                grid: {
                                    left: '0%',
                                    right: '4%',
                                    bottom: '0%',
                                    containLabel: true
                                },
                                xAxis: [
                                    {
                                        type: 'category',
                                        data: data[0],
                                        axisTick: {
                                            alignWithLabel: true
                                        },
                                        splitLine: {
                                            show: false,
                                            lineStyle: {
                                                color: ['#002331'],
                                                width: 1,
                                                type: 'solid'
                                            }
                                        },
                                        //坐标轴颜色
                                        axisLine: {
                                            lineStyle: {
                                                color: '#002331',
                                            }
                                        },
                                    }

                                ],
                                yAxis: [
                                    {
                                        type: 'value',
                                        splitLine: {
                                            show: true,
                                            lineStyle: {
                                                color: ['#002331'],
                                                width: 1,
                                                type: 'solid'
                                            }
                                        },
                                    }
                                ],
                                textStyle: {
                                    color: '#287378',
                                },
                                series: [
                                    {
                                        name: 'Direct',
                                        type: 'bar',
                                        barWidth: '30%',
                                        data: data[1],
                                        itemStyle: {
                                            normal: {
                                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                                    offset: 0,
                                                    color: '#fbda65'
                                                }, {
                                                    offset: 1,
                                                    color: '#07ba74'
                                                }]),
                                            }
                                        },
                                    }

                                ]
                            };
                            option && myChart.setOption(option);
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            weituodan() {
                this.$sa0.post({
                    url: this.$api('Daping_task'),
                    data: {}
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            var chartDom = document.getElementById('weituodannum');
                            var myChart = echarts.init(chartDom);
                            var option;
                            let data = response.data.data;
                            console.log(response.data.data)
                            option = {
                                tooltip: {
                                    trigger: 'axis',
                                    axisPointer: {
                                        type: 'shadow',
                                        label: {
                                            backgroundColor: '#6a7985'
                                        }
                                    }
                                },
                                grid: {
                                    left: '0%',
                                    right: '4%',
                                    bottom: '0%',
                                    containLabel: true
                                },
                                xAxis: [
                                    {
                                        type: 'category',
                                        data: data[0],
                                        axisTick: {
                                            alignWithLabel: true
                                        },
                                        splitLine: {
                                            show: false,
                                            lineStyle: {
                                                color: ['#002331'],
                                                width: 1,
                                                type: 'solid'
                                            }
                                        },
                                        //坐标轴颜色
                                        axisLine: {
                                            lineStyle: {
                                                color: '#002331',
                                            }
                                        },
                                    }

                                ],
                                yAxis: [
                                    {
                                        type: 'value',
                                        splitLine: {
                                            show: true,
                                            lineStyle: {
                                                color: ['#002331'],
                                                width: 1,
                                                type: 'solid'
                                            }
                                        },
                                    }
                                ],
                                textStyle: {
                                    color: '#287378',
                                },
                                series: [
                                    {
                                        name: 'Direct',
                                        type: 'bar',
                                        barWidth: '30%',
                                        data: data[1],
                                        itemStyle: {
                                            normal: {
                                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                                    offset: 0,
                                                    color: '#66ffff'
                                                }, {
                                                    offset: 1,
                                                    color: '#07ba74'
                                                }]),
                                            }
                                        },
                                    }

                                ]
                            };
                            option && myChart.setOption(option);
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })

            },


            smaple30Day() {
                this.$sa0.post({
                    url: this.$api('Dp_specitem'),
                    data: {}
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            var chartDom = document.getElementById('sampledata');
                            var myChart = echarts.init(chartDom);
                            var option;
                            let data = response.data.data;
                            console.log(response.data.data)
                            option = {
                                tooltip: {
                                    trigger: 'axis',
                                    axisPointer: {
                                        type: 'shadow',
                                        label: {
                                            backgroundColor: '#6a7985'
                                        }
                                    }
                                },
                                grid: {
                                    left: '0%',
                                    right: '4%',
                                    bottom: '0%',
                                    containLabel: true
                                },
                                xAxis: [
                                    {
                                        type: 'category',
                                        data: data[0],
                                        axisTick: {
                                            alignWithLabel: true
                                        },
                                        splitLine: {
                                            show: false,
                                            lineStyle: {
                                                color: ['#002331'],
                                                width: 1,
                                                type: 'solid'
                                            }
                                        },
                                        //坐标轴颜色
                                        axisLine: {
                                            lineStyle: {
                                                color: '#002331',
                                            }
                                        },
                                    }

                                ],
                                yAxis: [
                                    {
                                        type: 'value',
                                        splitLine: {
                                            show: true,
                                            lineStyle: {
                                                color: ['#002331'],
                                                width: 1,
                                                type: 'solid'
                                            }
                                        },
                                    }
                                ],
                                textStyle: {
                                    color: '#287378',
                                },
                                series: [
                                    {
                                        name: 'Direct',
                                        type: 'bar',
                                        barWidth: '30%',
                                        data: data[1],
                                        itemStyle: {
                                            normal: {
                                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                                    offset: 0,
                                                    color: '#09c982'
                                                }, {
                                                    offset: 1,
                                                    color: '#006490'
                                                }]),
                                            }
                                        },
                                    }

                                ]
                            };
                            option && myChart.setOption(option);
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            shiji7Day() {
                this.$sa0.post({
                    url: this.$api('daping_Reagent'),
                    data: {}
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            var chartDom = document.getElementById('shijikucun');
                            var myChart = echarts.init(chartDom, 'dark');
                            var option;
                            let data = response.data.result;
                            console.log(response.data.result)
                            option = {
                                backgroundColor: '',//背景颜色透
                                legend: {
                                    data: ['试剂出库', '试剂入库'],
                                },
                                grid: {
                                    left: '0%',
                                    right: '4%',
                                    bottom: '0%',
                                    containLabel: true
                                },

                                xAxis: {
                                    type: 'category',
                                    boundaryGap: false,
                                    data: data.data_rk[0],
                                    //坐标轴颜色
                                    splitLine: {
                                        show: false,
                                        lineStyle: {
                                            color: ['#002331'],
                                            width: 1,
                                            type: 'solid'
                                        }
                                    },
                                    //坐标轴颜色
                                    axisLine: {
                                        lineStyle: {
                                            color: '#002331',
                                        }
                                    },
                                },
                                yAxis: [
                                    {
                                        type: 'value',
                                        splitLine: {
                                            show: true,
                                            lineStyle: {
                                                color: ['#002331'],
                                                width: 1,
                                                type: 'solid'
                                            }
                                        },
                                    }
                                ],
                                textStyle: {
                                    color: '#287378',
                                },
                                series: [
                                    {
                                        name: '试剂出库',
                                        type: 'line',
                                        stack: 'Total',
                                        data: data.data_ck[1]
                                    },
                                    {
                                        name: '试剂入库',
                                        type: 'line',
                                        stack: 'Total',
                                        data: data.data_rk[1]
                                    },

                                ]
                            };
                            option && myChart.setOption(option);
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })


            }
        }
    }
</script>

<style scoped>
    .fcr{ color: #ff0000}

    .daping-main{ width: 1920px; height: 1080px; margin: 0 auto; background: url("http://api.tengbocn.com/upload/assets/dapingbg1.jpg") no-repeat top center; box-sizing: border-box; overflow: hidden }

    .daping-top{ width: 100%; height: 98px; background: url("http://api.tengbocn.com/upload/assets/daping-top.png") no-repeat top center; position: relative }

    .daping-top .title{ font-size: 38px; color: #ffffff; text-align: center; font-weight: bold; padding-top: 5px}

    .daping-top .back{ position: absolute; left: 40px; color: #ffffff; top: 45px }

    .daping-top .back i{ display: inline-block; vertical-align: middle}

    .daping-top .datatime{ position: absolute; right: 120px; color: #ffffff; top: 50px }

    .daping-mid{ width: 100%; display: flex; justify-content: space-between; padding: 10px; margin-top: 20px; box-sizing: border-box}

    .daping-mid-left{ width: 450px}

    .baogaonumcontainer{ width: 100%; background: rgba(0, 0, 0, 0.25); padding: 20px; box-sizing: border-box; border-radius: 2px; margin-bottom: 10px}

    .baogaonumcontainer .title{ font-size: 18px; color: #ffffff; }

    .baogaonumcontainer .maincon{ width: 100%; margin-top: 20px; min-height: 230px }

    .tognzhicontainer{ width: 100%; background: rgba(0, 0, 0, 0.25); padding: 20px; box-sizing: border-box; border-radius: 2px; margin-bottom: 10px }

    .tognzhicontainer .title{ font-size: 18px; color: #ffffff; }

    .tognzhicontainer .maincon{ width: 100%; font-size: 16px; color: #e3e4e5; margin-top: 20px; line-height: 36px; height: 150px; }

    .daping-mid-container{ width: 920px; }

    .daping-mid-contop{ width: 100%; height: 210px; background: url("http://api.tengbocn.com/upload/assets/dapingshumu.png") no-repeat top center; display: flex}

    .daping-mid-right{ width: 450px }

    .daping-mid-contop-left{ width: 450px }

    .daping-mid-contop-left-title{ color: #ffffff; font-size: 18px; text-align: right; padding-right: 140px; padding-top: 23px }

    .daping-mid-contop-left-con{ font-size: 72px; color: #66ffff; font-weight: bold; text-align: right; padding-right: 100px; margin-top: 20px}

    .daping-mid-contop-right{ width: 450px }

    .daping-mid-contop-right-title{ color: #ffffff; font-size: 18px; text-align: left; padding-left: 160px; padding-top: 23px }

    .daping-mid-contop-right-con{ font-size: 72px; color: #66ffff; font-weight: bold; text-align: left; padding-left: 100px; margin-top: 20px}

    .daping-mid-smaple-container{ width: 100%; background: rgba(0, 0, 0, 0.3); padding: 20px; margin-top: 20px; border-radius: 2px}

    .daping-mid-smaple-container .title{ color: #ffffff; font-size: 20px}

    .daping-kit-container{ width: 100%; background: rgba(0, 0, 0, 0.3); padding: 20px; margin-top: 20px; border-radius: 2px; height: 345px}

    .daping-kit-container .title{ width: 720px; margin: 0 auto; background: url("http://api.tengbocn.com/upload/assets/kittitlebg.png"); color: #ffffff; font-size: 20px; text-align: center; margin-top: -20px }

    .daping-kit-container-con{ width: 100%; display: flex; justify-content: space-around}

    .daping-kit-container-con-list{ margin-top: 20px }

    .kitcon{ font-size: 20px; color: #ffffff; display: flex; justify-content: space-between; font-size: 16px }

    .kit-wudu i{ display: inline-block; vertical-align: bottom; }

    .kit-shidu i{ display: inline-block; vertical-align: bottom; }

    .kitname{ color: #ffffff; width: 140px; height: 40px; background: url("http://api.tengbocn.com/upload/assets/kitnamebg.png"); text-align: center; line-height: 40px; margin: 0 auto; margin-top: 15px; margin-bottom: 10px; }

    .daping-weituodan-container{ width: 100%; background: rgba(0, 0, 0, 0.3); padding: 20px; border-radius: 2px}

    .daping-weituodan-container .title{ color: #ffffff; font-size: 20px}

    .daping-yiqi-container{ width: 100%; background: rgba(0, 0, 0, 0.3); padding: 20px; margin-top: 20px; border-radius: 2px }

    .daping-yiqi-container .title{ color: #ffffff; font-size: 20px}

    .dapingyiqi-con{ width: 100%; margin-top: 20px; padding-bottom: 10px}

    .dapingyiqi-con-title{ width: 100%; display: flex; justify-content: space-between; color: #66ffff; background: #14434a; padding: 5px 10px}

    .dapingyiqi-con-title-left{ width: 80%}

    .dapingyiqi-con-title-right{ width: 20%}

    .dapingyiqi-con-mid{height: 460px; overflow-y: auto}

    .dapingyiqi-con-mid-list{ width: 100%; display: flex; justify-content: space-between; color: #ffffff; line-height: 35px }

    .dapingyiqi-con-mid-list-left{ width: 80%}

    .dapingyiqi-con-mid-list-right{ width: 20%}

    .dapingyiqi-con-mid-list{ padding: 5px; box-sizing: border-box}

    .dapingyiqi-con-mid-list:nth-child(even){ background: #08262f}
</style>
